$( document ).ready(function() {
    // If input "model_name" exists and the var defaults is defined (only apllies on "add motor") set default values in inputs by given "model_name"
    if($('#model_name').length && typeof defaults !== 'undefined') {setDefaultValues(defaults[$('#model_name').val()])}

    $('.infohover.text-input').hover(function(){
        arrowtop = $(this).position().top + $(this).children('input, select').position().top + ($(this).children('input, select').outerHeight() / 2) - 20;
        $('.arrow').css('top', arrowtop );
        $('.description').html($(this).children('.text').html());
    });

    $('.infohover.radio-input').hover(function(){
        arrowtop = $(this).position().top + ($(this).find('.overlay').outerHeight() / 2) + $(this).find('span').outerHeight() - 20;
        $('.arrow').css('top', arrowtop );
        $('.description').html($(this).children('.text').html());
    });

    $('.infohover.select-input').hover(function(){
        arrowtop = $(this).position().top + $(this).find('.custom-select').position().top + ($(this).find('select').outerHeight() / 2) - 20;
        $('.arrow').css('top', arrowtop );
        $('.description').html($(this).children('.text').html());
    });

    $('.infohover input, .infohover select').focus(inputevent).keypress(inputevent);
    $('.description').html($('.text').html());
});

function inputevent(){
    arrowtop = $(this).parent().position().top + $(this).position().top + ($(this).outerHeight() / 2) - 20;
    //arrowtop = $(this).position().top;
    $('.arrow').css('top', arrowtop );
    $('.description').html($(this).siblings('.text').html());
}

// Listen on changes to "model_name" and onChange set default values in inputs by given "model_name"
$('#model_name').change(function(){setDefaultValues(defaults[$(this).val()])});

// Determine the default values and set them into the inputs key<=>input#id and value is the new input value
function setDefaultValues(sourceOfDefaults) {
    $.each(sourceOfDefaults, function( key, value ) {
        if($('#' + key).length) {
            if ($('#' + key).prop('type') == 'select-one') {
                if(key == 'derated') {
                    if(value)
                        $('#' + key + ' option[value="yes"]').attr("selected", "selected");
                    else
                        $('#' + key + ' option[value="no"]').attr("selected", "selected");
                }
                $('#' + key + ' option[value="' + value + '"]').attr("selected", "selected");
            } else {
                $('#' + key).val(value);
            }
        }
    });
}